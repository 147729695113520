<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <strong>
          </strong> Archived VO Commissioning Forms
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details

          ></v-text-field>
        </v-card-title>

        <v-data-table
            :headers="headers"
            :items="vocommissionings"
            item-key="id"
            class="elevation-1 table-one"
            multi-sort
            :search="search"
            v-model="selectedRows"
            show-select
        >

          <template v-slot:item.action="{item}">
            <div class="d-flex">
              <v-card-title>
                <v-btn
                    color="green"
                    dark
                    outlined
                    @click.stop="viewVocommissioning(item.id)">
                  View Details
                </v-btn>
              </v-card-title>
              <v-card-title>
                <v-btn
                    color="red"
                    dark
                    outlined
                    @click="delRecord(item.id)">
                  Delete
                </v-btn>
              </v-card-title>
              <v-card-title>
                <v-btn
                    color="blue"
                    dark
                    outlined
                    @click="unArchiveRecord(item)">
                  <v-icon
                      dark
                  >
                    mdi-archive-minus
                  </v-icon>
                </v-btn>
              </v-card-title>
            </div>
          </template>
        </v-data-table>
      </base-card>
    </v-col>

    <ConfirmDialog ref="confirmDelete" />
  </v-row>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import ConfirmDialog from "@/components/shared/ConfirmDialog";


export default {
  name: "Vocommissionings",
  components: {
    ConfirmDialog
  },
  data() {
    return {
      search: '',
      items: [],
      selectedRows: [],
      headers: [
        {
          text: 'Job Number',
          sortable: true,
          value: 'jobNumber',
          align: 'left',
        },
        {
          text: 'Engineer',
          sortable: true,
          value: 'engineerName',
          align: 'left',
        },
        {
          text: 'Customer Name',
          sortable: true,
          value: 'customerName',
          align: 'left',
        },
        {
          text: 'Site Location',
          sortable: true,
          value: 'metadata.siteAddress1',
          align: 'middle',
        },
        {
          text: 'Postcode',
          sortable: true,
          value: 'metadata.sitePostcode',
          align: 'middle',
        },
        {
          text: 'Work Date',
          sortable: true,
          value: 'workDate',
          align: 'middle',
        },
        {
          text: 'Actions',
          value: 'action',
        }
      ],
    }
  },
  computed: {
    ...mapState('vocommissionings', {loadingVocommissionings : 'isFindPending'}),
    ...mapGetters('vocommissionings', {findVocommissioningsInStore : 'find'}),

    vocommissionings() {
      return this.findVocommissioningsInStore({ query: {archived: { $ne: 0 }}}).data || [];
    },
    selectedVocommissioningIds() {
      return this.selectedRows.map(a => a.id);
    }
  },
  methods: {
    ...mapActions('vocommissionings', { findVocommissionings: 'find' }),
    deleteItem(id) {
      this.$store.dispatch('vocommissionings/remove', id)
    },
    async delRecord(id) {
      if (
          await this.$refs.confirmDelete.open(
              "Confirm",
              "Are you sure you want to delete this record?"
          )
      ) {
        this.deleteItem(id);
      }
    },
    viewVocommissioning(id) {
      this.$router.push('/app/vocomissioning/'+id)
    },
    getVocommissionings(){
      fetch('https://emissis-app.com/api/reports/vocommissioning?vocommissioningIds='+this.selectedVocommissioningIds, {
        method: 'GET',
        headers: {"Authorization": "Bearer " + this.$store.state.auth.accessToken}
      }).then((res) => {
        console.log(res);
        window.location = 'https://emissis-app.com/api/assetList.pdf'
      });
      console.log(this.selectedVocommissioningIds);
    },
    async unArchiveRecord(item) {
      item.archived = 0;
      item.patch();
    },
    async unArchiveSelected() {
      for (const vocommissioningId of this.selectedVocommissioningIds) {
        const { Vocommissioning } = this.$FeathersVuex.api
        const thisVocommissioning = await Vocommissioning.get(vocommissioningId)
        thisVocommissioning.archived = 0;
        thisVocommissioning.patch()
      }
    },
  },
  mounted() {
    this.findVocommissionings();
  }
}
</script>

<style scoped>

</style>
